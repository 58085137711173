<template>
 <Nav color="blue"></Nav>
  <section id="shipping-returns" class="padding-main">
    <div class="container-large">
      <div class="intro title-only">
        <h1 class="blue">Shipping Policy</h1>
      </div>
      <div>
        <h4>Shipping</h4>
        <div class="mb-1">
          <p><i>If you have any questions regarding shipping, please email us at <a href="mailto:hello@redbluestories.com">hello@redbluestories.com</a></i></p>
          <p class="small-text">Postcards & Stickers : Flat shipping to Canada & US - 2CAD</p>
          <p class="small-text">Everything else: Flat shipping to Canada & US - 10CAD </p>
        </div>
        <div class="mb-1">
          <h5>Canada & US:</h5>
          <p>Orders are sent by Canada Post, Expedited Parcels / Tracked Packet (estimated 5 to 10 business days) or UPS.</p>
          <h5>International</h5>
          <p>Please contact us at <a href="mailto:hello@redbluestories.com">hello@redbluestories.com</a> if you wish to order from another country than US & Canada. </p>
        </div>
        <div class="mb-1">
          <p><i>Shipping charges do not include possible taxes and charges imposed by the country of origin or destination. The responsibility of costs incurred for any customs duties, foreign taxes or other fees rest solely with the customer. If the package is rejected by the customer at the destination and safely returned to us a refund will be offered for the goods only. Shipping fees are non-refundable.</i> </p>
          <p><i>For all orders please make sure you provide a secure delivery address as we cannot take responsibility for lost or stolen packages.</i></p>
        </div>
      </div>
    </div>
  </section>
  <Footer color="teal"></Footer>
</template>

<script>

import Nav from "../../components/Nav.vue";
import Footer from "../../components/Footer.vue";
export default {
  name: 'DeviceCompatibility',
  components: {Footer, Nav},
  methods: {

  }
}
</script>

<style lang="scss" scoped>
@import '../../assets/styles/variables';

#shipping-returns {
  h5 {
    font-weight: 600;
  }
}

@media only screen and (max-width: 920px) {
  #shipping-returns {
    min-height: 40vh;
  }
}

</style>