<template>
  <div id="newsletter-signup">
    <div id="newsletter-modal" class="modal blue">
      <h1 class="centered">Keep in the loop</h1>
      <p class="mb-1">Subscribe to our newsletter and be the first to know about new product launches and upcoming events / markets! You'll also get the chance to playtest new products before anyone else and enjoy behind-the-scenes content.</p>
      <p class="mb-3">We won’t send more than one email a month, we know how busy our mailbox can get :)</p>
      <NewsletterSignupBlock></NewsletterSignupBlock>
      <img v-if="showAbsoluteImage" src="@/assets/img/landing-pages/newsletter/girl.png" class="absolute-bottom img-1" id="absolute-img">
    </div>
  </div>
</template>

<script>
import NewsletterSignupBlock from "@/components/NewsletterSignupBlock.vue";

export default {
  name: "NewsletterSignup",
  components: {NewsletterSignupBlock},
  data() {
    return {
      isMounted: false,
      showAbsoluteImage: false
    }
  },
  methods: {
    shouldIShowAbsoluteImage() {
        const modal = document.getElementById("newsletter-modal");
        var t1 = (window.innerHeight - modal.clientHeight) * 0.5 * 1.1;
        var t2 = window.innerHeight * 0.34;
        if (t1 < t2) {
          this.showAbsoluteImage = true;
        } else {
          this.showAbsoluteImage = false;
        }
    }
  },
  mounted() {
    this.shouldIShowAbsoluteImage();
    window.addEventListener("resize", this.shouldIShowAbsoluteImage);
  }
}
</script>

<style scoped lang="scss">
@import "@/assets/styles/variables.scss";

#newsletter-signup {
  background-image: url("../../assets/img/landing-pages/newsletter/background.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100dvh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.modal {
  max-width: calc(min(700px, 90vw));
  background-color: $cream;
  border-radius: 20px;
  padding: 3em;
  display: flex;
  flex-direction: column;
  position: relative;
  margin: 0 1em;
}

img.absolute-bottom {
  position: absolute;
  top: 90%;
  right: 10%;
}

img.img-1 {
  max-height: 34vh;
}

</style>