<template>
  <section id="app">
   </section>
</template>

<script>

export default {
  name: 'ProductReviewPage',
  data() {
    return {
      redirectPath: "https://ccpjv9on2mx.typeform.com/to/MkxdQgNK"
    }
  },
  methods: {

  },
  mounted() {
    window.location.href = this.redirectPath;
  }
}
</script>

<style lang="scss" scoped>

#app {
  padding-top: 6em;
  padding-bottom: 6em;

  h3 {
      margin: 1em 0 1em 0;
  }

  .app-section {
     margin-bottom: 1em;
  }

  img {
    max-width: 8em;
    border-radius: 20px;
  }
}

</style>