<script>
export default {
  name: "hidden-app-redirect",
  data() {
    return {
      redirect: {
        ios: {
          path: 'https://apps.apple.com/app/tales-from-the-trails/id6466105381',
          status: true
        },
        android: {
          path: 'https://play.google.com/store/apps/details?id=com.redbluestories.TalesFromTheTrails',
          status: true
        }
      }
    }
  },
  methods: {
    isAppleDevice() {
      return [
        'iPad Simulator',
        'iPhone Simulator',
        'iPod Simulator',
        'iPad',
        'iPhone',
        'iPod'
      ].includes(navigator.userAgent)
      // iPad on iOS 13 detection
      || (navigator.userAgent.includes("Mac"))
    },
  },
  mounted() {
    if (/Android/i.test(navigator.userAgent)) {
        if (this.redirect.android.status) {
          window.location.href = this.redirect.android.path;
        }
      } else if (/iPhone|iPod|iPad|Mac/i.test(navigator.userAgent)) {
        if (this.redirect.ios.status) {
          window.location.href = this.redirect.ios.path;
        }
      }
  }

}
</script>

<template>

</template>

<style scoped lang="scss">

</style>