<template>
<svg class="hover-teal" width="25" height="30" viewBox="0 0 25 30" fill="#2E4F9B" xmlns="http://www.w3.org/2000/svg">
<path d="M24.9894 10.6658C25.0895 12.352 24.4697 14.0793 23.5051 15.7512C21.5439 19.1521 18.7074 21.4116 14.9939 22.6263C14.742 22.7084 14.458 23.0156 14.383 23.2729C13.8811 25.0323 13.4524 26.8131 12.9469 28.5707C12.829 28.9798 12.5807 29.421 12.261 29.6871C11.7251 30.1301 10.982 29.8711 10.5819 29.053C9.72813 27.3043 8.9172 25.5324 8.16699 23.7355C7.91871 23.1407 7.66328 22.9388 6.99882 22.921C4.47849 22.8531 2.72622 21.3884 1.45445 19.4039C0.00583502 17.1462 -0.120985 14.5491 0.068352 11.9537C0.361289 7.94546 2.41542 4.88748 5.41267 2.3868C7.17208 0.920327 9.27087 0.245143 11.5179 0.0593778C16.2871 -0.331801 20.2721 1.19719 23.2675 5.02145C24.5322 6.63617 24.9877 8.11336 24.9877 10.6658H24.9894ZM11.3375 26.3648L11.5072 26.3576C11.768 25.2859 12.0931 24.2267 12.2717 23.1425C12.4664 21.9529 12.5003 21.4456 14.2329 20.9329C17.1177 20.1059 20.4507 17.3355 22.0672 14.1436C23.4087 11.4964 23.3551 8.81713 21.6296 6.3736C19.429 3.25489 16.2674 1.93132 12.5003 2.00634C10.6677 2.04206 8.87969 2.37251 7.34891 3.44423C2.97629 6.504 1.22402 10.6855 2.19036 15.8208C2.8066 19.0949 4.60352 20.6793 7.86334 21.0115C8.50816 21.0776 8.98329 21.3384 9.26015 21.9493C9.82816 23.205 10.4158 24.4535 10.9981 25.7039C11.1035 25.9289 11.2232 26.1451 11.3357 26.3666L11.3375 26.3648Z"/>
<path d="M19.5291 13.8328C19.4827 14.7223 19.154 15.1707 18.3074 15.3636C14.651 16.1906 10.9947 17.0194 7.32223 17.7714C5.72894 18.0982 5.12877 17.5642 5.00553 15.9423C4.85548 13.9775 4.67329 12.0109 4.61613 10.0425C4.57148 8.51527 4.85548 8.24377 6.30588 7.72398C8.74583 6.84874 11.209 6.05388 13.8044 5.8056C15.1494 5.67699 16.5051 5.63412 17.8573 5.58054C18.4967 5.55553 18.8236 6.02888 18.9308 6.54866C19.1719 7.70612 19.3487 8.87965 19.497 10.0532C19.5649 10.5908 19.5684 13.1201 19.5309 13.8328H19.5291ZM6.51308 15.9852C6.52022 16.0763 6.59525 16.1477 6.68634 16.1513C6.9382 16.1602 13.9473 14.633 17.2785 13.8882C17.7411 13.7846 17.9001 13.5399 17.8805 13.0862C17.8197 11.6911 17.9251 9.98353 17.4393 7.54C17.4286 7.48463 17.3535 7.46855 17.3232 7.51678C17.1856 7.73827 16.9802 8.04371 16.8391 8.16875C15.2458 9.59056 13.6597 11.0213 12.036 12.4056C11.2787 13.0504 10.4159 12.9951 9.57463 12.5164C9.26562 12.3413 7.04001 11.1124 6.11833 10.6373C6.25587 12.5074 6.38269 14.224 6.51308 15.9852ZM16.1103 6.88089C15.4369 6.93984 9.65322 7.97048 6.8078 8.98325C6.78816 8.9904 6.78816 9.01719 6.8078 9.02612C8.15281 9.77276 9.32635 10.4479 10.532 11.0678C10.7267 11.1678 11.134 11.1035 11.3108 10.9552C12.8166 9.69595 14.6385 8.21161 16.13 6.92198C16.1479 6.90769 16.1371 6.87911 16.1139 6.88089H16.1103Z"/>
</svg>

</template>

<script>
export default {
  name: "MailSVG"
}
</script>

<style scoped>

</style>