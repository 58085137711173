<template>
  <div class="row" :class="$props.simplify ? '' : 'large-format'">
    <span @click ="updateQuantity(-1)" :class="quantity === 0 ? 'disabled' : ''">-</span>
    <input type="number" min="0" :max="$props.itemInfo.maxPurchase" v-model="quantity" @input="validateInput">
    <span class="plus" @click="updateQuantity(+1)" :class="quantity === $props.itemInfo.maxPurchase ? 'disabled' : ''">+</span>
  </div>
</template>

<script>

export default {
  name: 'QuantityRange',
  props: ['itemInfo', 'simplify'],
  methods: {
    updateQuantity(value) {
      if (this.quantity + value >= 0 && this.quantity + value <= this.$props.itemInfo.maxPurchase) {
        this.quantity += value;
        this.submitNewQuantity();
      }
    },
    validateInput() {
      if (this.quantity < 0) {
        this.quantity = 0;
      } else if (this.quantity > this.$props.itemInfo.maxPurchase) {
        this.quantity = this.$props.itemInfo.maxPurchase;
      }
    },
    submitNewQuantity() {
      this.$emit('quantityUpdated', this.$props.itemInfo.itemId, this.quantity);
    }
  },
  data() {
    return {
      quantity: this.$props.itemInfo.quantity
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../assets/styles/variables.scss';

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
  background-color: transparent;
  color: $font-dark;
  text-align: center;
  font-size: 1.5em;
  font-weight: 700;
  font-family: 'Karla', sans-serif;
  border: none;
  width: 50px;

  &:focus-visible {
    outline: $teal-hover 2px solid;
  }
}

.large-format {
  //justify-content: space-between;
  min-width: 80%;
  max-width: 100%;
  gap: 1em;
  input[type=number] {
    flex-grow: 20;
    border-radius: 40px;
    //max-width: 200px;
    //min-width: 50%;
    border: 1px $teal solid;
  }
}


span {
  background-color: $teal-hover;
  border-radius: 40px;
  font-family: 'Karla', sans-serif;
  font-weight: 400;
  font-size: 40px;
  line-height: 1em;
  color: $cream;
  padding: 0.1em 1.2rem;
  border: none;
  text-align: center;
  width: 30px;
  height: 42px;
  transition: background-color .2s ease-in;

  &.disabled {
    background-color: #C0DDDD;

    &:hover {
      background-color: #C0DDDD;
    }
  }

  &.plus {
    padding: 0.05em 1.2rem 0.1em 1.2rem;
  }

  &:hover {
    background-color: $dark-teal;
    cursor: pointer;
    transition: background-color .2s ease-in;
  }
}

@media only screen  and (max-width: 320px) {
  input[type=number] {
    min-width: 40%;
  }
}

</style>