<template>
  <div class="card card-container bg-blue teal col gap-3">
    <div class="row">
      <h2>How does it work?</h2>
      <p>Welcome to fun interactive storytime! - where beautifully illustrated puzzle pieces coexist with cute and engaging animated stories, where choices you make impact how the story goes, where the Frozen Lands are home to a little polar bear longing for an adventure, and where the Northern Lights have little companions dancing high in the sky with them!</p>
    </div>
    <div class="row gap-3 align-items-center reverse">
      <div class="basis-60-gap-3" v-if="!this.isMobile">
        <img class="card" v-lazy="{src: require('../../assets/img/landing-pages/tbwttnl/TBWTTNL_cards-fit.jpg'), loading: require('../../assets/img/landing-pages/tbwttnl/TBWTTNL_cards-fit_loading.jpg')}" alt="close up shot of kid's hands connecting puzzle pieces. The piece he is holding features a little polar bear standing on the edge of an icefloe, lookign scared.">
      </div>
      <div class="basis-40-gap-3">
        <h3>1. Build the puzzle</h3>
        <img v-if="this.isMobile" class="card mb-1" v-lazy="{src: require('../../assets/img/landing-pages/tbwttnl/TBWTTNL_cards-fit.jpg'), loading: require('../../assets/img/landing-pages/tbwttnl/TBWTTNL_cards-fit_loading.jpg')}" alt="close up shot of kid's hands connecting puzzle pieces. The piece he is holding features a little polar bear standing on the edge of an icefloe, lookign scared.">
        <p>First step: taking the 16 story cards out of the box and putting them together, just like for a normal puzzle. Each card is a chapter in the little bear’s story!</p>
        <p class="note">* Some pieces are a little different, so the first time playing, we recommend following the instructions on the start card!</p>
      </div>
    </div>
    <div class="row gap-3 align-items-center">
      <div class="basis-40-gap-3">
        <h3>2. Point your device at the puzzle & wacth the story unfold</h3>
         <img v-if="this.isMobile" class="card large right-s mb-1" v-lazy="{src: require('../../assets/img/landing-pages/tbwttnl/TBWTTNL_app_feather.jpg'), loading: require('../../assets/img/landing-pages/tbwttnl/TBWTTNL_app_feather_loading.jpg')}" alt="Close up shot of an ipad scanning one of the puzzle piece. The screen shows a slightly different scene than the one shown on the card : a little bear is going up a snowy mountain, accompanied by a little arctic fox. He is holding a feather.">
        <p>Now that the puzzle is built, let’s jump into telling the story!</p>
        <p>Download our companion app (available on the AppStore and Google Play), and use it to scan story cards.</p>
        <p>The illustrations of the cards come to life with hand drawn-animation and compelling narration, revealing the charming story of a little polar bear searching for the place <i>where the earth and the sky meet!</i></p>
        <div class="row gap-1 card-container-outline mt-1 align-items-center">
          <div class="basis-35-gap-1">
            <img class="app-icon" src="../../assets/img/the-bear-chapters_app-icon.png">
          </div>
          <div class="col basis-65-gap-1">
            <h4 class="reset-m-p">The Bear Chapters</h4>
            <p>available on <a href="https://apps.apple.com/app/id1630877428" class="teal">iOS</a> and <a href="https://play.app.goo.gl/?link=https://play.google.com/store/apps/details?id=com.redbluestories.TheBearChapters" class="teal">Android</a></p>
            <p class="note">*AR-compatible device required.
Includes iPhone (6s & later), iPad Pro (all), iPads(5th gen & later), Samsung Galaxy (S7 & later) & Google Pixel (all). <router-link to="device-compatibility" class="teal details-link">See device compatibility.</router-link>
</p>
            </div>
        </div>
      </div>
      <div class="basis-60-gap-3" v-if="!this.isMobile">
        <img class="card large right-s" v-lazy="{src: require('../../assets/img/landing-pages/tbwttnl/TBWTTNL_app_feather.jpg'), loading: require('../../assets/img/landing-pages/tbwttnl/TBWTTNL_app_feather_loading.jpg')}" alt="Close up shot of an ipad scanning one of the puzzle piece. The screen shows a slightly different scene than the one shown on the card : a little bear is going up a snowy mountain, accompanied by a little arctic fox. He is holding a feather.">
      </div>
    </div>
    <div class="row gap-3 align-items-center">
      <div class="basis-60-gap-3" v-if="!this.isMobile">
        <img class="card" style="width: 100%" v-lazy="{src: require('../../assets/img/landing-pages/tbwttnl/TBWTTNL_choice.jpg'), loading: require('../../assets/img/landing-pages/tbwttnl/TBWTTNL_choice_loading.jpg')}" alt="Close up shot of an ipad, scanning one of the puzzle pieces and showing a little bear thinking. A fish, a blanket and a feather are visible and can be selected.">
      </div>
      <div class="basis-40-gap-3">
        <h3>3. Explore up to 9 story variations</h3>
        <img v-if="this.isMobile" class="card mb-1" style="width: 100%" v-lazy="{src: require('../../assets/img/landing-pages/tbwttnl/TBWTTNL_choice.jpg'), loading: require('../../assets/img/landing-pages/tbwttnl/TBWTTNL_choice_loading.jpg')}" alt="Close up shot of an ipad, scanning one of the puzzle pieces and showing a little bear thinking. A fish, a blanket and a feather are visible and can be selected.">
        <p>We sprinkled choices in each part of the experience, so each time they play, little humans can experience a slightly different story!</p>
        <p><i>Who do they want the little bear to meet? A little penguin or a mysterious walrus?</i></p>
        <img class="card" src="../../assets/img/landing-pages/tbwttnl/TBWTTNL_render_special-cards.png" alt="a stack of puzzle pieces, all of them with illustration and a symbol on their bottom edge.">
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "TBWTTNLConcept",
  methods: {
    resizeHandler() {
      this.isMobile = window.innerWidth < 1024;
    }
  },
  created() {
     this.isMobile = window.innerWidth < 1024;
     window.addEventListener("resize", this.resizeHandler);
  }
}
</script>

<style scoped>
@media only screen and (max-width: 1024px) {
  .basis-60-gap-3, .basis-40-gap-3 {
    flex-basis: unset;
  }

  .app-icon {
    max-width: 100px;
  }
}

</style>