<template>
 <Nav color="blue"></Nav>
  <section id="compatibility" class="padding-main">
    <div class="container-large">
      <h1 class="blue">Device Compatibility</h1>
      <p>List of devices compatible with The Bear Who Touched The Northern Lights. Not all devices have been tested.</p>
      <p class="note">This page is under construction. We are working on making it more digestible.</p>
      <div class="ios">
        <h5>IOS</h5>
        <p>iPhone 6s & later, all iPhone SE, all iPads Pro, iPad (5th gen & later), iPad Mini (5th gen & later)</p>
      </div>
      <div class="android">
        <h5>Android</h5>
        <p>Devices running Android 7.0 or later & supporting ARCore (includes: Samsung Galaxy S7 & later, all Google Pixel).</p>
        <p>The full list of Android devices that are AR enabled can be found <a href="https://developers.google.com/ar/devices#google_play_devices">here</a>. </p>
      </div>
    </div>
  </section>
  <Footer color="teal"></Footer>
</template>

<script>

import Nav from "../../components/Nav.vue";
import Footer from "../../components/Footer.vue";
export default {
  name: 'DeviceCompatibility',
  components: {Footer, Nav},
  methods: {

  }
}
</script>

<style lang="scss" scoped>
@import '../../assets/styles/variables';

.ios, .android {
  margin: 2em 0;
}


</style>