<template>
  <div id='test'></div>
  <button @click="updateQuantity">Update Quantity</button>
</template>

<script>

export default {
  name: "BuyButtonSample",
  data() {
    return {
      client: null,
      product: null
    }
  },
  methods: {
    updateQuantity() {

    },
    ShopifyBuyInit() {
      let self = this;
      self.client = ShopifyBuy.buildClient({
        domain: 'checkout.redbluestories.com',
        storefrontAccessToken: 'f814544bec8ac71fd4567fdc48096b0e',
      });
      var ui = ShopifyBuy.UI.init(self.client);

        self.product = ui.createComponent('product', {
          id: 7644979298462,
          node: document.getElementById('test'),
          options: {
            "product": {
              "contents": {
                "img": false,
                "title": false,
                "price": false,
                "quantity" : true
              }
            }
          }
        });
    }
    //   ShopifyBuy.UI.onReady(self.client).then(function (ui) {
    //     ui.createComponent('product', {
    //       id: '7644979298462',
    //       node: document.getElementById('product-component-1706063398233'),
    //       moneyFormat: '%24%7B%7Bamount%7D%7D',
    //       options: {
    //         "product": {
    //           "contents": {
    //             "img": false,
    //             "title": false,
    //             "price": false
    //           },
    //           "text": {
    //             "button": "Add to cart"
    //           }
    //         },
    //         "modalProduct": {
    //           "contents": {
    //             "img": false,
    //             "imgWithCarousel": true,
    //             "button": false,
    //             "buttonWithQuantity": true
    //           },
    //           "styles": {
    //             "product": {
    //               "@media (min-width: 601px)": {
    //                 "max-width": "100%",
    //                 "margin-left": "0px",
    //                 "margin-bottom": "0px"
    //               }
    //             }
    //           },
    //           "text": {
    //             "button": "Add to cart"
    //           }
    //         },
    //         "option": {},
    //         "cart": {
    //           "text": {
    //             "total": "Subtotal",
    //             "button": "Checkout"
    //           }
    //         },
    //         "toggle": {}
    //       },
    //     });
    //   });
    // }
    , windowHandling() {
      window.open = function (open) {
        return function (url, name, features) {
        // Pass through non-shopify URLS
        if (url.indexOf("checkout") < 0) {
        return open.call(window, url, name, features);
        }
        // Use current window
        console.log("Redirecting to:", url);
        window.location.href = url;
        return null;
        };
        }(window.open);
    }
    },
    mounted() {
      if (window.ShopifyBuy) {
        if (window.ShopifyBuy.UI) {
          this.ShopifyBuyInit();
        }
      }
      this.windowHandling();
    }
  }
</script>

<style scoped>

</style>
