<template>
  <section id="cards">
    <div class="bg-col width-10 teal"></div>
    <div class="card fg card-container">
      <h2 class="blue">Story cards by the plenty!</h2>
      <p class="blue">Here are all the 16 story cards included in the box, 15 of which are chapters of our story.<br>
        <i>Hover over the cards to discover the chapter names!</i></p>
      <div id="card-list" class="row">
        <div class="story-card" v-for="card in storyCards" :key="card.chapterNumber" @mouseover="cardHovered(card.chapterNumber, true)" @mouseleave="cardHovered(card.chapterNumber, false)">
          <img v-lazy="{src: require('../../assets/img/cards/' + card.frontCardPath), loading: require('../../assets/img/cards/' + card.loadingPath)}" :class="[currentCardNumber === card.chapterNumber ? 'blurred' : '']" :alt="card.alt">
          <div class="col card-hover" v-if="cardHover && currentCardNumber === card.chapterNumber">
            <p class="small"> chapter {{card.chapterNumber}}</p>
            <p class="chapter-name">{{card.chapterName}}</p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "TBWTTNLCards",
  data() {
    return {
      cardHover : false,
      currentCardNumber: '-1',
      storyCards: [
        {
          chapterNumber: '0',
          chapterName: 'Instruction Card',
          frontCardPath: '1-start_v8e_front.png',
          loadingPath:  '1-start_v8e_front_loading.jpg',
          alt: 'start card - instruction on what card to put back in the box before playing.'
        },
        {
          chapterNumber: '1',
          chapterName: 'The Frozen Lands',
          frontCardPath: '2-lonely-bear_v8e.png',
          loadingPath: '2-lonely-bear_v8e_loading.jpg',
          alt: 'A lonely polar little bear sitting on the ice, looking bored.'
        },
        {
          chapterNumber: '2',
          chapterName: 'The Dancing Lights',
          frontCardPath: '3-longing-nl_v8e.png',
          loadingPath: '3-longing-nl_v8e_loading.jpg',
          alt: 'a little bear sitting on an ice cliff, looking at the northern lights in the sky.'
        },
        {
          chapterNumber: '3a',
          chapterName: 'Words with a Traveller',
          frontCardPath: '4-penguin-helper_v8e.png',
          loadingPath: '4-penguin-helper_v8e_loading.jpg',
          alt: 'a little penguin and a little polar bear are sitting on the ice and talking.'
        },
        {
          chapterNumber: '3b',
          chapterName: 'Words with a Shaman',
          frontCardPath: '4-shaman-helper_v8e.png',
          loadingPath: '4-shaman-helper_v8e_loading.jpg',
          alt: 'a red walrus is sitting in a cave, with talismans around him.'
        },
        {
          chapterNumber: '4',
          chapterName: 'The Adventure Begins',
          frontCardPath: '5-leaving-home_v8e.png',
          loadingPath: '5-leaving-home_v8e_loading.jpg',
          alt: 'a little polar bear is standing on the edge of an icefloe, looking on the other side, terrified.'
        },
        {
          chapterNumber: '5a',
          chapterName: 'A Grumpy Friend',
          frontCardPath: '6-fox_v8e.png',
          loadingPath: '6-fox_v8e_loading.jpg',
          alt: 'a little fox is sleeping on the ice, surrounded by black little birds at sunrise.'
        },
        {
          chapterNumber: '5b',
          chapterName: 'A Pointy Friend',
          frontCardPath: '6-narwhal_v8e.png',
          loadingPath: '6-narwhal_v8e_loading.jpg',
          alt: 'a tusk is sticking out from an icefloe. The ice has several round looking holes on its surface.'
        },
        {
          chapterNumber: '6',
          chapterName: 'The High Seas',
          frontCardPath: '7-whale_v8e.png',
          loadingPath: '7-whale_v8e_loading.jpg',
          alt: 'a blue whale is making bubbles underwater.'
        },
        {
          chapterNumber: '7',
          chapterName: 'The Tall Mountain',
          frontCardPath: '8-reach-mountain_v8e.png',
          loadingPath: '8-reach-mountain_v8e_loading.jpg',
          alt: 'a tall volcanic mountain can be seen in the distance, a little polar bear is swimming towards it'
        },
        {
          chapterNumber: '8a',
          chapterName: 'The Mountaineer',
          frontCardPath: '9-climb_v8e.png',
          loadingPath: '9-climb_v8e_loading.jpg',
          alt: 'a little polar bear is climbing up a steep snow slope.'
        },
        {
          chapterNumber: '8b',
          chapterName: 'The Ice Climber',
          frontCardPath: '9-tunnel_v8e.png',
          loadingPath: '9-tunnel_v8e_loading.jpg',
          alt: 'a little polar bear is walking through an ice tunnel. Threatening icicles hang from the ceiling.'
        },
        {
          chapterNumber: '9',
          chapterName: 'Where the Earth and the Sky Meet',
          frontCardPath: '10-top-mountain_v8e.png',
          loadingPath: '10-top-mountain_v8e_loading.jpg',
          alt: 'a little is a the top of the volcanic mountain. He is watching the sun setting.'
        },
        {
          chapterNumber: '10',
          chapterName: 'A Reflective Moment',
          frontCardPath: '11-lake-and-cry_v8e.png',
          loadingPath: '11-lake-and-cry_v8e_loading.jpg',
          alt: 'the little bear is sitting next to the summit lake, watching his reflection in the water and looking sad.'
        },
        {
          chapterNumber: '11',
          chapterName: 'The Secret of the Night Sky',
          frontCardPath: '12-down-the-mtn_v8e.png',
          loadingPath: '12-down-the-mtn_v8e_loading.jpg',
          alt: 'the little polar bear is heading down the mountain, through grass and flowers. The Northern Lights are shinning.'
        },
        {
          chapterNumber: '12',
          chapterName: 'The Bear who Touched the Northern Lights',
          frontCardPath: '13-end_v8e.png',
          loadingPath: '13-end_v8e_loading.jpg',
          alt: 'The little polar bear is in the water at the feet of the mountain, playing. The Northern Lights are shinning.'
        }
      ]
    }
  },
  methods: {
    cardHovered(cardNb, state) {
      this.cardHover = state;
      if (state)
        this.currentCardNumber = cardNb;
      else
        this.currentCardNumber = -1;
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../assets/styles/variables.scss';

#cards {
  position: relative;

  .bg-col {
    right: 0;
  }

  #card-list {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    margin-top: 3em;
    margin-bottom: -1em;

    .story-card {
      width: 100%;
      position: relative;

      img {
        width: 100%;
      }

      .card-hover {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #2e4f9cbd;
        z-index: 2;
        display: flex;
        justify-content: center;
        text-align: center;
        border-radius: 20px;

        p {
          color: $light-font;

          &.chapter-name {
            margin: 0 .5em;
            font-size: 1.4em;
            font-weight: 700;
            //font-family: Poppins, sans-serif;
          }
        }
      }

      .blurred {
        filter: blur(4px);
      }
    }
  }
}

@media only screen and (max-width: 1024px) {
  #cards #card-list {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media only screen and (max-width: 920px) {
  #cards #card-list {
    grid-template-columns: repeat(2, 1fr);
  }
}

//@media only screen and (max-width: 747px) {
//  #cards #card-list {
//    grid-template-columns: repeat(1, 1fr);
//  }
//}
</style>