<template>
  <Nav color="blue"></Nav>
  <section id="shop" class="padding-main">
    <div class="container-large">
      <h1 class="blue">Shop</h1>
      <div class="row mt-2 mb-4">
        <div class="mb-2">
          <label for="filter" class="blue">filter by:</label>
          <select id="filter" name="filter" v-model="filterSelected">
            <option value="none">None</option>
            <option value="hidden-postcards">Hidden: AR Postcards</option>
            <option value="interactive-story">Interactive Stories</option>
          </select>
        </div>
        <div class="product-grid grid-4">
          <ProductThumbnail v-for="product in products" v-bind:key="product.itemId" :product="product" v-show="containsTag(product.tags)"></ProductThumbnail>
        </div>
      </div>
    </div>
  </section>
  <Footer color="teal"></Footer>
</template>

<script>
import Footer from "@/components/Footer.vue";
import ProductThumbnail from "@/components/ProductThumbnail.vue";
import Nav from "@/components/Nav.vue";

export default {
  name: 'Shop',
  props: ['filter'],
  components: {
    ProductThumbnail,
    Footer,
    Nav
  },
  data () {
    return {
      filterSelected: 'none'
    }
  },
  computed: {
    products() {
      return this.$store.getters.getAllProducts;
    }
  },
  methods: {
    containsTag(tags) {
      if (this.filterSelected === 'none') return true;
      return tags.includes(this.filterSelected);
    }
  },
  created() {
    if (this.filter != null) {
      this.filterSelected = this.filter;
    }
  }

}
</script>

<style lang="scss" scoped>
@import "../../assets/styles/variables";

#sorting-options {
  margin-bottom: 2em;
}

label {
  font-family: Poppins, sans-serif;
  font-size: 1.2em;
  font-weight: 600;
  margin-right: 1em;
}

select {
  font-family: Poppins, sans-serif;
  background-color: transparent;
  font-size: 1em;
  border: none;

  &:focus {
    border: none;
  }
}

</style>