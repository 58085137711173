<template>
    <div class="card  product-card" @click="goToProduct(product.slug)">
      <div>
        <img v-lazy="{src: require(`../assets/img/product/${product.itemId}/${product.info.productImages[product.info.thumbnailImg].path}`), loading: require(`../assets/img/product/${product.itemId}/${product.info.productImages[product.info.thumbnailImg].load}`)}">
        <p class="title blue"><span v-if="product.tags.includes('coming-soon')" class="teal coming-soon-txt">Coming Soon | </span> {{product.info.projectName}}</p>
        <p class="tagline blue">{{product.info.subtitle}}</p>
      </div>
      <div>
        <p class="description blue align-bottom">${{product.info.price[0].value}} {{product.info.price[0].currency}}</p>
      </div>
<!--      <div v-if="product.tags.includes('coming-soon')" class="coming-soon-overlay">-->
<!--        <p>coming soon</p>-->
<!--      </div>-->
    </div>
</template>

<script>
import router from '../router/index.js'

export default {
  name: "ProductThumbnail",
  props: ['product'],
  methods: {
    goToProduct(slug) {
      router.push({ name: 'product', params: { productName: slug }})
    }
  }
}
</script>

<style scoped lang="scss">
@import "../assets/styles/variables.scss";
img {
  border-radius: 20px;
  margin-bottom: 1em;
  cursor: pointer;
}

p {
  padding-bottom: 0.3rem;

  &.title {
    font-family: Poppins, sans-serif;
    font-weight: bold;
    font-size: 1rem;
  }

  &.tagline {
    font-style: italic;
    font-size: 1rem;
  }
}

.product-card {
  position: relative;
  background-color: $cream;
  padding: 1rem;
  border-radius: calc(20px + 0.5rem) ;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &:hover {
    background-color: $cream-hover;
    cursor: pointer;
  }
}

.coming-soon-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.6);
  top: 0;
  left: 0;
  border-radius: calc(20px + 0.5rem);
  display: flex;
  justify-content: center;
  align-items: center;


  p {
    font-family: 'Poppins', sans-serif;
    color: $blue;
    font-weight: bold;
    font-size: 1.8rem;
  }
}

</style>