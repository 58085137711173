<template>
  <div class="card card-container-cream blue row gap-3">
    <div class="left" v-if="!this.isMobile">
      <img :src="require('../assets/img/' + app.productImage)">
      <h5>{{app.productName}}</h5>
    </div>
    <div class="right">
      <div class="tag">companion app</div>
      <div class="row card-container-outline mt-1 align-items-center mb-1" @click="goToAppPage()">
          <div class="basis-35-gap-1">
            <img class="app-icon" :src="require('../assets/img/' + app.appIcon)">
          </div>
          <div class="col basis-65-gap-1">
            <p class="app-name">{{ app.appName }}</p>
            <p>available on <a :href="app.appLinks.iOS">iOS</a> and <a :href="app.appLinks.android">Android</a></p>
            </div>
        </div>
      <p>{{app.description}}</p>
    </div>
  </div>
</template>

<script>
import router from "../router/index.js";

export default {
  name: "CompanionAppBundle",
  props: ['app'],
  methods: {
    goToAppPage() {
      router.push('/' + this.app.pageName);
    },
    resizeHandler() {
      this.isMobile = window.innerWidth < 920;
    }
  },
  created() {
     this.isMobile = window.innerWidth < 920;
     window.addEventListener("resize", this.resizeHandler);
  }
}
</script>

<style scoped lang="scss">
@import '../assets/styles/variables.scss';

.left {
  max-width: 200px;
}

.right {
  max-width: calc(100% - 200px - 3em);
}

h5 {
  font-weight: bold;
  text-align: center;
}

.tag {
  font-weight: bold;
  font-family: 'Poppins', sans-serif;
  padding: 0.5em 1.5em;
  background-color: $teal;
  max-width: fit-content;
  border-radius: 20px;
}

.card-container-outline {
  max-width: 350px;
  border-color: $teal;
  border-radius: 30px;
  cursor: pointer;

  img {
    max-width: 100px;
  }
}

.card {
  margin: 1em 0;
}

.app-name {
  font-weight: bold;
  font-size: 1.2rem;
  font-family: 'Poppins', sans-serif;
}

@media only screen and (max-width: 1024px) {
  .right {
    max-width: 100%;
  }
}

@media only screen and (max-width: 747px) {
  .app-icon {
    margin-right: 1em;
  }
}
</style>