<template>
  <footer :class="this.color === 'teal' ? 'bg-teal' : 'bg-blue'">
    <div class="card container-large">
      <div class="row">
        <div class="left">
        <p><i>We acknowledge that we are living on the traditional, ancestral and unceded territory of the Coast Salish peoples–Sḵwx̱wú7mesh (Squamish), Stó:lō and Səl̓ílwətaʔ/Selilwitulh (Tsleil-Waututh) and xʷməθkʷəy̓əm (Musqueam) Nations.<br>
  We respect the land and honour this tradition of land acknowledgment that dates back centuries for Indigenous people.<br>
          With this acknowledgment, we thank those Indigenous people who still live on and care for these lands.</i></p>
        </div>
        <div class="right row">
          <div class="mid col">
            <router-link to="/compatibility">Device Compatibility</router-link>
            <router-link to="/privacy-policy">Privacy Policy</router-link>
            <router-link to="/shipping">Shipping Policy</router-link>
            <router-link to="/returns">Returns & Refunds</router-link>
            <router-link to="/terms-of-service">Terms of service</router-link>
<!--            <a href="https://redbluestories.notion.site/Red-Blue-Stories-Press-Kit-29273b3b44f24fa49aba86f86a2110fe" target="_blank">Press Kit</a>-->
          </div>
          <div id="contact" class="right-s">
            <h5>Contact</h5>
            <p>PO Box 3222 Garibaldi Highlands PO, <br>
              Garibaldi Highlands BC V0N 1T0, <br>
              Canada </p>
            <p><a href="mailto:hello@redbluestories.com">hello@redbluestories.com</a></p>
          </div>
        </div>
      </div>
    <div style="padding-top: 2em">
      <p class="copyright">©2024, Red + Blue Stories Inc. All rights reserved.<br/> Designed in Squamish, BC.</p>
    </div>
    </div>
  </footer>
</template>

<script>

export default {
  name: 'Footer',
  props: ['color'],
  methods: {
    scrollTo(elt) {
      var element = document.getElementById(elt);
      var topElt = element.offsetTop;
      window.scroll({top: topElt, left: 0, behavior: 'smooth'});
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../assets/styles/variables.scss';

footer {
  padding-top: 4em;

  &.bg-teal {
    background-color: $teal;
  }

  &.bg-blue {
    background-color: $blue;
  }

  .card {
    //width: 80%;
    margin: auto;
    background-color: $cream;
    padding: 2em;
    border-radius: 40px 40px 0 0;
  }

  .row {
    justify-content: space-between;
  }

  h5 {
    font-weight: 600;
    margin-bottom: 1em;
  }

  .copyright {
    font-size: 14px;
    font-weight: 600;
  }

  .mid {
    gap: .5em;
  }

  .right {
    width: 60%;
  }

  .left {
    width: 30%;
  }

  .mid, .right-s {
    width: 50%;
  }
}

@media only screen and (max-width: 1240px) {
  footer {
    .left, .right {
      width: 50%;
    }
    .mid, .right-s {
      width: 100%;
      padding: 0 3em;
    }

    .right {
      flex-direction: column;
      justify-content: space-between;
    }
  }
}

@media only screen and (max-width: 920px) {
  footer {
    padding-top: 2em;
    .card {
      .row {
        flex-direction: column;
      }
      .left, .right, .mid, .right-s {
        width: 100%;
        padding: 0;
      }
      .mid {
        margin: 2em 0;
      }
    }
  }
}

</style>