<template>
  <section id="postcard-details" class="hidden-lp">
      <div id="popup-close" @click="closePage">
         <img src="../../assets/img/landing-pages/hidden/h_icon_arrow_red.png">
      </div>
      <div class="container-large">
        <img :src="require('@/assets/img/landing-pages/hidden/' + postcardDetails.thumbnail)">
        <div class="col gap-1 postcard-details-container">
          <h4 class="handwritten hidden-red"> {{ postcardDetails.name }} </h4>
          <div>
            <p class="subtitle">Collection</p>
            <p>{{ collections[postcardDetails.season - 1].name }}</p>
          </div>
          <div>
            <p class="subtitle">Credits</p>
            <p v-if="postcardDetails.credits.art != null">Art by <span>{{ postcardDetails.credits.art }}</span></p>
            <p v-if="postcardDetails.credits.animation != null">Animations by <span>{{ postcardDetails.credits.animation }}</span></p>
            <p v-if="postcardDetails.credits.voiceOver != null">Voice-over by <span>{{ postcardDetails.credits.voiceOver }}</span></p>
          </div>
          <button class="outline red" @click="shopPostcard">shop postcard</button>
        </div>
        <div id="hidden-story">
          <img class="icon" src="../../assets/img/landing-pages/hidden/h_icon_watch_red.png">
          <p class="subtitle hidden-red">Story</p>
          <p style="white-space: pre-line">{{ postcardDetails.story }}</p>
        </div>
        <div id="hidden-interactions">
          <img class="icon" src="../../assets/img/landing-pages/hidden/h_icon_interaction_red.png">
          <p class="subtitle hidden-red">Interactions</p>
          <div class="col gap-1">
            <p style="white-space: pre-line">{{ postcardDetails.interactions.text }}</p>
            <img v-for="image in postcardDetails.interactions.imgs" :src="require('@/assets/img/landing-pages/hidden/' + image)">
          </div>
        </div>
      </div>
      <div id="hidden-gallery">
        <div class="container-large">
          <h2>Gallery</h2>
          <div class="gallery-container">
            <img v-for="image in postcardDetails.galleryImgs" :src="require('@/assets/img/landing-pages/hidden/' + image)">
          </div>
        </div>
      </div>
    </section>
</template>

<script>

import router from "@/router/index.js";
export default {
  name: 'HiddenLP',
  data() {
    return {
    }
  },
  methods: {
    closePage() {
      console.log('close-page');
      router.push({name: 'HiddenARPostcards', hash: '#hidden-collection'});
    },
    shopPostcard() {
      router.push('/product/hidden-s' + this.postcardDetails.season + 'e' + this.postcardDetails.episode);
    }
  },
  computed: {
    postcardDetails() {
      console.log(this.$route.params.cardSlug);
      return this.$store.getters.getHiddenPostcardBySlug(this.$route.params.cardSlug);
    },
    collections() {
      return this.$store.getters.getHiddenCollections;
    },
  },
  mounted() {
    document.body.style.backgroundColor = "#EFEFEF";
  },
  unmounted() {
    document.body.style.backgroundColor = "#8BCFCF";
  }
}

</script>

<style scoped lang="scss">
@import "@/assets/styles/variables.scss";

#postcard-details {
  z-index: 10;
  background-color: $hidden-light-grey;
  min-width: 100%;
  padding-top: 4em;

  p.subtitle {
    font-weight: 600;
  }

  span {
    font-weight: 800;
    font-style: italic;
  }

  h4 {
    padding: 0;
  }

  .postcard-details-container {
    padding: 1em 0;
  }

  .icon {
    max-width: 60px;
  }

  #hidden-interactions, #hidden-story, #hidden-gallery {
    padding-top: 4em;

    img {
      border-radius: 1em;
    }

    .subtitle {
      font-weight: 700;
      font-size: 1.4em;
    }

    p {
      line-height: 140%;
    }
  }

  #hidden-gallery {
    margin-top: 4em;
    background-color: $hidden-dark-grey;
    color: $hidden-light-grey;
    padding-bottom: 4em;

    .gallery-container {
      margin-top: 1em;
      display: flex;
      flex-wrap: nowrap;
      overflow-x: scroll;
      overflow-y: hidden;
       gap: 1em;
      -webkit-overflow-scrolling: touch;

      &::-webkit-scrollbar {
        display: none;
      }

      img {
        flex: 0 0 auto;
        max-width: 75vw;
      }
    }
  }

   #popup-close {
      background-color: $hidden-light-grey-2;
      padding: 1.5em;
      max-width: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 200px;
     z-index: 10;
      //position: absolute;
      top: 1em;
      left: 1em;
      position: fixed;

      img {
        width: 20px;
      }
    }
}


</style>